<template>
  <div :id="exam.slug">
    <div :class="['p-6', {'sm:flex justify-between items-center' : layoutLarge }]">
      <div class="flex flex-col">
        <div class="flex">
          <Icon
            view-box="0 0 304 304"
            fill="none"
            class="h-12 w-14"
          >
            <component
              :is="icon"
            />
          </Icon>
          <div class="ml-4">
            <h2
              class="text-xl sm:text-2xl"
            >
              {{ exam.name }}
            </h2>
            <p class="text-sm text-gray-600 capitalize">
              {{ exam.level }}
            </p>
            <AntiCheatResults :candidate="candidate" />
          </div>
        </div>
      </div>

      <div
        :class="['sm:flex items-center mt-10', {'mt-4 sm:mt-0' : layoutLarge }]"
      >
        <ul
          class="flex items-center justify-between py-4 text-sm text-center text-gray-600 sm:py-0"
        >
          <li class="leading-none sm:mr-4 lg:mr-10">
            <p>
              Answered
            </p>
            <p class="mt-2">
              <span class="mt-2 text-lg text-gray-800">{{ exam.attempt.questionsAnswered }}</span> / {{ exam.attempt.questionsTotal }}
            </p>
          </li>
          <li
            v-if="exam.attempt.speedCompleted"
            class="leading-none sm:mr-4 lg:mr-10"
          >
            <p>
              Time
            </p>
            <p
              class="mt-2 text-lg text-gray-800"
            >
              {{ secondsToHuman(exam.attempt.speedCompleted) }}
            </p>
          </li>
          <li
            v-if="exam.attempt.usedExtraTime"
            class="leading-none sm:mr-4 lg:mr-10"
          >
            <p>
              Extra time
            </p>
            <p
              class="mt-2 text-lg text-gray-800"
            >
              Yes
            </p>
          </li>
        </ul>
        <template>
          <div class="items-center justify-between w-64 leading-none sm:flex">
            <div class="flex flex-col w-full">
              <div
                class="w-full h-2 mt-4 bg-gray-300 sm:mt-0"
              >
                <div
                  :class="['h-full', scoreColor(exam.attempt.percentageBand, exam.attempt.scorePercentage, 'bg')]"
                  :style="`width: ${exam.attempt.scorePercentage}%`"
                >
                </div>
              </div>
              <p :class="['text-xs tracking-wider mt-3', scoreColor(exam.attempt.percentageBand)]">
                {{ exam.attempt.bracket }}
              </p>
            </div>
            <div class="flex items-center w-24 mt-4 sm:mt-0 sm:ml-4">
              <span class="text-5xl">{{ exam.attempt.scorePercentage }}</span><span class="ml-1 text-lg text-gray-600">%</span>
            </div>
          </div>
        </template>
      </div>
    </div>

    <div
      v-if="exam.averageScorePercentage && showGraph"
      :class="['text-gray-600 border-t border-gray-300 p-6', {'md:flex md:justify-between' : layoutLarge }]"
    >
      <template>
        <div :class="['bg-gray-100 p-4 mb-6 md:mb-0', {'md:w-2/3 md:w-2/3 md:mr-6' : layoutLarge }]">
          <div class="items-center justify-between mb-4 sm:flex">
            <h3 class="text-xl font-medium text-gray-800">
              Performance
            </h3>
            <ul class="items-center justify-between my-2 sm:flex sm:my-0">
              <li class="flex items-center sm:mr-6 sm:justify-between">
                <span class="inline-flex w-6 h-2 mr-2 bg-graph-secondary"></span>
                <p class="text-sm">
                  Candidate
                </p>
              </li>
              <li class="flex items-center sm:justify-between">
                <span class="inline-flex w-6 h-2 mr-2 bg-gray-primary"></span>
                <p class="text-sm">
                  Average
                </p>
              </li>
            </ul>
          </div>

          <DistributionCurveGraph
            v-if="exam.distributionGraph"
            :exam="exam"
            :index="index"
          />
        </div>
        <div :class="['flex flex-col justify-between space-y-6 md:space-y-0 mb-6 text-lg', {'md:w-1/3 mb-0' : layoutLarge }]">
          <article class="flex-1 pt-4">
            <p class="text-xl font-medium text-gray-800">
              Summary
            </p>
            <p
              class="py-4"
              v-html="parseNarrative(exam.attempt.scorePercentage, exam.attempt.percentageBand)"
            >
            </p>
            <ul class="ml-5 space-y-6 list-disc list-outside">
              <li
                v-for="(summaryPoint, i) in exam.attempt.summaryPoints"
                :key="i"
                v-html="parseStrong(summaryPoint)"
              >
              </li>
            </ul>
          </article>
        </div>
      </template>
    </div>
  </div>
</template>

<script>
import { secondsToHuman } from '@utils/time'
import scoreColor from '@utils/scoreColor'
import Icon from '@components/Icons/Icon'
import DistributionCurveGraph from '@components/Candidates/DistributionCurveGraph'
import AntiCheatResults from '@components/AntiCheatResults'

import { mapGetters } from 'vuex'

export default {
  components: {
    Icon,
    DistributionCurveGraph,
    AntiCheatResults
  },

  props: {
    icon: {
      type: Function,
      default: null
    },
    candidate: {
      type: Object,
      default: null
    },
    exam: {
      type: Object,
      default: null
    },
    index: {
      type: Number
    },
    layout: {
      type: String,
      default: 'large'
    },
    showGraph: {
      type: Boolean,
      default: true
    }
  },

  data() {
    return {
      secondsToHuman,
      scoreColor
    }
  },

  computed: {
    layoutLarge() {
      return this.layout === 'large'
    },

    ...mapGetters({
      maskedAttributes: 'organisations/maskedAttributes'
    })
  },

  methods: {
    /**
     * @param {Number} score
     * @param {string} percentageBand
     * @return {string}
     */
    parseNarrative(score, percentageBand) {
      if (!this.exam.narratives) {
        return
      }

      let examNarrative = this.exam.narratives[percentageBand]

      if (!examNarrative) {
        return
      }

      const strings = {
        '[name]': this.maskedAttributes.includes('name') ? `<strong class="text-xl">Anonymous</strong> ` : `<strong class="text-xl">${this.candidate.firstName} ${this.candidate.surname}</strong>`,
        '[score]': `<strong class="text-xl">${this.exam.attempt.scorePercentage}%</strong>`,
        '[global average]': `<strong class="text-xl">${this.exam.averageScorePercentage}%</strong>`
      }

      examNarrative = examNarrative.replace(/\[name\]|\[score\]|\[global average\]/gi, matched => {
        return strings[matched]
      })

      return examNarrative
    },

    /**
     * @param {string} str
     * @return {string}
     */
    parseStrong(str) {
      if (typeof str !== 'string') {
        return
      }

      str = str.replace(/\[/g, '<strong class="text-xl">')
      str = str.replace(/\]/g, '</strong>')
      return str
    }
  }
}
</script>
